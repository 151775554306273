import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    chatList: [],
    chatNextPage: null,
  },

  getters: {
    chatList: (state) => state.chatList,
    chatNextPage: (state) => state.chatNextPage,
  },

  mutations: {
    CLEAN_CHAT(state) {
      state.chatList = [];
      state.chatNextPage = null;
    },
    GET_CHAT_LIST(state, payload) {
      if (payload) {
        state.chatList = [...payload, ...state.chatList];
      }
    },
    GET_CHAT_NEXT_PAGE(state, payload) {
      state.chatNextPage = payload ? payload.split("page=")[1] : null;
    },
    PUSH_MESSAGE(state, payload) {
      state.chatList.push(payload);
    },
  },

  actions: {
    async getChat({ commit, dispatch }, data) {
      const newPage = data.page ? `?page=${data.page}` : "";
      commit("GET_CHAT_LIST", null);
      commit("projects/LOADING_STATE", true, { root: true });
      await axios
        .get(`/user-chat/${data.id}${newPage}`)
        .then((response) => {
          if (response.data && response.data.status === "Success") {
            commit("GET_CHAT_LIST", response.data.data.data);
            commit("GET_CHAT_NEXT_PAGE", response.data.data.next_page_url);
            dispatch("events/getUsersNotifications", null, { root: true });
            //   dispatch("getPagination", response.data.data);
          }
        })
        .catch((err) => {
          console.error("Error from getChat: ", err);
        });
      commit("projects/LOADING_STATE", false, { root: true });
    },
    async sendMessage(state, data) {
      await axios.put("project-message", data).catch((err) => {
        console.error("Error from getChat: ", err);
      });
    },
    clean({ commit }) {
      commit("CLEAN_CHAT");
    },
    async showMessage({ commit }, data) {
      commit("PUSH_MESSAGE", data);
    },
  },
};
