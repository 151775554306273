<template>
  <svg
    v-bind="$attrs"
    :width="width"
    :height="height"
    :fill="$attrs.active ? '#17a2b8':'#828282'"
    viewBox="0 0 256.06 201.96"
  >
    <path d="M233.23,126.67c0,9-.06,18.14,0,27.28,0,3.91-.62,7.4-4.65,9.09s-7-.26-9.83-2.92c-10.95-10.24-21.88-20.5-33.05-30.5a13,13,0,0,0-7.4-3c-6.59-.41-13.22-.14-20.2-.14,0,6,.08,11.33,0,16.64-.27,15.22-11.8,26.84-27,27-20,.15-40-.05-60,.16a12.16,12.16,0,0,0-7.06,2.6c-9.89,8.16-19.53,16.62-29.26,25-3.06,2.63-6.08,5.48-10.64,3.42s-4.67-6-4.65-10.15c0-6.16.22-12.34-.15-18.48A6.63,6.63,0,0,0,16.26,168C5.6,162.49.1,153.93.06,142,0,123.81,0,105.65.06,87.49.15,71.62,11.55,60.15,27.51,59.94c13.49-.17,27,0,40.48,0h6c0-10,.53-19.43-.12-28.83C72.59,12.62,86.65-.27,104.74,0c40.3.62,80.62.19,120.93.2,19.41,0,30.38,11,30.39,30.29q0,31.25,0,62.47C256.06,112.8,251.71,119.3,233.23,126.67Zm-15.16,12c0-7-.06-12.63,0-18.27.09-6.09,2.77-8.79,8.81-9.11,10.2-.54,14-4.48,14-14.65q0-33.24,0-66.48c0-10.19-4.44-14.72-14.55-14.73q-61.49,0-123,0c-9.82,0-14.28,4.59-14.29,14.46q0,33.24,0,66.48c0,10.71,4.11,14.89,14.7,14.9,26.33,0,52.66-.13,79,.18a16.54,16.54,0,0,1,9.7,3.82C201,122.44,209,130.21,218.07,138.64ZM74,75.09c-15.67,0-30.78-.06-45.89,0-8,0-12.79,4.74-12.85,12.76q-.21,27.22,0,54.45c.06,7.15,4.08,11.47,11,12.61,6.63,1.08,8.46,3.17,8.55,9.88,0,3.9,0,7.82,0,12.89,7.74-6.62,14.65-12.27,21.25-18.27a16.54,16.54,0,0,1,12.15-4.5c20.31.2,40.63.11,60.94.07,7.32,0,12.64-3.33,13.37-9.42s.16-12.44.16-19.07h-5.85c-12,0-24,.2-36-.09C85.13,126,74.15,114.62,74,98.88,73.91,91.23,74,83.58,74,75.09Z"/><path d="M164.87,42c14,0,28,0,41.95,0,6.32,0,9.73,2.64,9.8,7.41s-3.49,7.72-9.57,7.73q-42.2,0-84.4,0c-7.33,0-11.85-6-8.13-11.17,1.53-2.12,5.18-3.73,7.9-3.8C136.56,41.75,150.72,42,164.87,42Z"/><path d="M165.08,88.41H124.63C117,88.4,113.37,86,113.3,81s3.78-7.74,11.15-7.74h80.89a35.34,35.34,0,0,1,4,.07c4.49.52,7.64,3.89,7.23,8-.44,4.43-3,7-7.54,7.06-10.16.11-20.31.06-30.46.08Z"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 47 },
    height: { type: [Number, String], default: 47 },
    iconColor: { type: String, default: "#007BFF" },
  },
};
</script>
