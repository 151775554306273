import axios from "@/api/axios";
import setToken from "@/helpers/persistanceStorage";

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    token: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },

    user(state) {
      return state.user;
    },

    userInfo(state) {
      return state.userInfo;
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, payload) {
      state.authenticated = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_USERINFO(state, payload) {
      state.userInfo = payload;
    },
  },

  actions: {
    async signIn({ commit, dispatch }, credentials) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.post("/auth/login/user", credentials).then((res) => {
        setToken(res.data.data.token);
        localStorage.setItem("authTokenUser", res.data.data.token);
      });
      commit("projects/LOADING_STATE", false, { root: true });
      dispatch("me");
      dispatch("projects/getCategories", null, { root: true });
      return true;
    },

    me({ commit, dispatch }) {
      return axios
        .get("/me")
        .then((response) => {
          commit("SET_AUTHENTICATED", true);
          commit("SET_USER", response.data);
          dispatch("getUserInfo",  response.data.id);
        })
        .catch((error) => {
          localStorage.removeItem("authTokenUser");
          localStorage.clear();
          commit("SET_AUTHENTICATED", false);
          commit("SET_USER", error);
          commit("SET_USER", null);
          console.error("Error from auth state:", error);
        });
    },

    getUserInfo({ commit }, id) {
      return axios
        .get("/user/" + id)
        .then((response) => {
          commit("SET_USERINFO", response.data.data);
        })
        .catch((error) => {
          commit("SET_USER", error);
          commit("SET_USER", null);
          console.error("Error getting user info:", error);
        });
    },

    async updateUserAccount({ commit }, data) {
      commit("projects/LOADING_STATE", true, { root: true });
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      return await axios
        .post("/user/" + data.id, data.formData, config)
        .then((response) => {
          commit("SET_USER", response.data.data);
          commit("projects/LOADING_STATE", false, { root: true });
          return response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async signOut({ commit }) {
      commit("projects/LOADING_STATE", true, { root: true });
      await axios.post("/auth/logout").then(() => {
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", null);
        localStorage.clear();
        sessionStorage.clear();
      });

      commit("projects/LOADING_STATE", false, { root: true });
      return true;
    },
  },
};
