<template>
  <div class="notification-wrapper">
    <button class="notification" @click="isOpenNotificationBar = !isOpenNotificationBar">
      <IconBell :active="hasNotifications"/>
    </button>

    <transition name="fade">
      <div v-show="isOpenNotificationBar" class="notification-menu">
        <ul>
          <li v-if="notificationsAdminPSMessages && notificationsAdminPSMessages > 0">
            <router-link :to="{ name: 'Messenger' }" class="pointer" @click="()=>{isOpenNotificationBar = false}">
              <span class="notification-menu-text">TSS Messages: {{ notificationsAdminPSMessages }}</span>
            </router-link>
          </li>
          <li v-if="notificationsProposals && notificationsProposals > 0">
            <span class="notification-menu-text">Proposals: {{ notificationsProposals }}</span>
          </li>
          <li v-if="notificationsProjectMessages && notificationsProjectMessages > 0">
            <span class="notification-menu-text">Projects Messages: {{ notificationsProjectMessages }}</span>
          </li>
          <li v-if="notificationsProposals || notificationsProjectMessages">
            <ul>
              <template v-for="(item, index) in notificationsByTypes" :key="index" >
                <li v-if="item.count > 0">
                  <router-link :to="{ name: `${item.link}` }" class="pointer" @click="()=>{isOpenNotificationBar = false}">
                      <component
                          :is="item.icon"
                          icon-color="#17a2b8"
                          width="15"
                          height="15"
                      />
                    <span class="notification-menu-text">{{ item.title }}: {{ item.count }}</span>
                  </router-link>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import IconBell from "../icons/IconBell.vue";
import IconFlame from "../icons/IconFlame.vue";
import IconFolder from "../icons/IconFolder.vue";
import IconInfo from "../icons/IconInfo.vue";
import IconQuestion from "../icons/IconQuestion.vue";

export default {
  components: {
    IconFlame,
    IconBell,
    IconFolder,
    IconInfo,
    IconQuestion,
  },
  setup() {
    const store = useStore();
    const isOpenNotificationBar = ref(false);

    const hasNotifications = computed(() => store.getters["events/hasNotifications"]);
    const notificationsTotal = computed(() => store.getters["events/notificationsTotal"]);
    const notificationsProjectMessages = computed(() => store.getters["events/notificationsProjectMessages"]);
    const notificationsAdminPSMessages = computed(() => store.getters["events/notificationsAdminPSMessages"]);
    const notificationsProposals = computed(() => store.getters["events/notificationsProposals"]);
    const notificationsByTypes = computed(() => store.getters["events/notificationsByTypes"]);
    store.dispatch("events/getUsersNotifications");

    return {
      isOpenNotificationBar,
      hasNotifications,
      notificationsTotal,
      notificationsProjectMessages,
      notificationsAdminPSMessages,
      notificationsProposals,
      notificationsByTypes
    };
  },
  created: function () {
    let self = this;

    window.addEventListener("click", function (e) {
      if (!self.$el.contains(e.target)) {
        self.isOpenNotificationBar = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: auto;
  height: auto;
  background-color: transparent;
  margin: 0 20px;

  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &-menu {
    position: absolute;
    z-index: 2;
    right: -50%;
    top: 100%;
    min-width: 210px;
    max-width: 300px;
    margin-top: 3px;
    padding: 10px 15px 14px;
    border: 1px solid var(--col-border);
    border-radius: 7px;
    background-color: var(--col-bg-secondary);

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      right: 56px;
      top: -6px;
      width: 12px;
      height: 12px;
      border: 1px solid transparent;
      border-top-color: var(--col-border);
      border-left-color: var(--col-border);
      transform: rotate(45deg);
      background-color: var(--col-bg-secondary);
    }

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 16px 5px 12px 0;

      &.pointer {
        padding: 5px 5px 5px 5px;
      }
    }

    &-text {
      padding-left: 10px;
      font-family: "SFProDisplay-Regular", serif;
      font-weight: 400;
      // line-height: 24px;
      font-size: 14px;
      color: var(--col-primary-dark);
    }

    li {
      &:hover {
        .notification-menu-text {
          color: var(--col-text-secondary);
        }

        svg {
          fill: var(--col-warning);
        }
      }
      &:last-of-type hr {
        display: none;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
