import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    chatting: null,
  },

  getters: {
    chatting: (state) => state.chatting,
  },

  mutations: {
    SET_CHATTING(state, payload) {
      state.chatting = payload;
    },
    PUSH_MESSAGE(state, payload) {
      state.chatting.push(payload);
    },
  },

  actions: {
    async showChatting({ commit, dispatch }) {
      await axios
        .get("ps-admin-chat")
        .then((res) => {
          if (res.data.status) {
            if (res.data.status === "Success") {
              commit("SET_CHATTING", res.data.data.data);
              dispatch("events/getUsersNotifications", null, {root: true});
            }
          }
        })
        .catch((err) => {
          console.error("Error from showChatting: ", err);
        });
    },

    async sendMessage(state, msg) {
      await axios.put("admin-ps-chat", msg).catch((err) => {
        console.error("Error from showAdminChat: ", err);
      });
    },

    showMessage({ commit }, msg) {
      commit("PUSH_MESSAGE", msg);
    },
  },
};
