import pusher from "@/api/pusher";
import axios from "axios";

export default {
  namespaced: true,

  state: {
    subscribedOnMe: false,
    notificationsTotal: 0,
    notificationsProjectMessages: 0,
    notificationsByTypes: {
      draft: {
        count: 0,
        link: "ProjectList",
        icon: "IconQuestion",
        title: "Clients Requests",
      },
      feasibility: {
        count: 0,
        link: "FeasibilityList",
        icon: "IconInfo",
        title: "Feasibility Requests",
      },
      project: {
        count: 0,
        link: "ActiveList",
        icon: "IconFlame",
        title: "Active Project",
      },
      reopen: {
        count: 0,
        link: "ArchiveList",
        icon: "IconFolder",
        title: "Archived Projects",
      },
    },
    notificationsAdminPSMessages: 0,
    notificationsProposals: 0,
    hasNotifications: false,
  },

  getters: {
    subscribedOnMe: (state) => state.subscribedOnMe,
    hasNotifications: (state) => state.hasNotifications,
    notificationsTotal: (state) => state.notificationsTotal,
    notificationsProjectMessages: (state) => state.notificationsProjectMessages,
    notificationsByTypes: (state) => state.notificationsByTypes,
    notificationsProjectDraftMessages: (state) => state.notificationsByTypes.draft.count,
    notificationsProjectFeasibilityMessages: (state) => state.notificationsByTypes.feasibility.count,
    notificationsProjectProjectMessages: (state) => state.notificationsByTypes.project.count,
    notificationsProjectReopenMessages: (state) => state.notificationsByTypes.reopen.count,
    notificationsAdminPSMessages: (state) => state.notificationsAdminPSMessages,
    notificationsProposals: (state) => state.notificationsProposals,
  },

  mutations: {
    SET_NOTIFICATIONS_DATA(state, payload) {
      state.notificationsTotal = payload.total;

      state.notificationsByTypes.draft.count = payload.draft ?? 0;
      state.notificationsByTypes.feasibility.count = payload.feasibility ?? 0;
      state.notificationsByTypes.project.count = payload.project ?? 0;
      state.notificationsByTypes.reopen.count = payload.reopen ?? 0;

      state.notificationsProjectMessages = payload.project_messages ?? 0;
      state.notificationsAdminPSMessages = payload.admin_ps_messages ?? 0;
      state.notificationsProposals = payload.proposal ?? 0;

      state.hasNotifications = payload.total > 0;
    },
    SET_SUBSCRIBED_ON_ME(state, payload) {
      state.subscribedOnMe = payload;
    },
    SET_HAS_NOTIFICATIONS(state, payload) {
      state.hasNotifications = payload;
    },
  },

  actions: {
    async subscribeUsersChannel({ commit, dispatch }, data) {
      const globalChannel = pusher.subscribe(`private-${btoa(data.email)}`);
      globalChannel.bind_global((event) => {
        if (event === "notification") {
          dispatch("getUsersNotifications");
        }
      });
      commit("SET_SUBSCRIBED_ON_ME", true);
    },

    async unSubscribeUsersChannel({ commit }, data) {
      pusher.unsubscribe(btoa(data.email));
      commit("SET_SUBSCRIBED_ON_ME", false);
    },

    async getUsersNotifications({ commit }) {
      await axios
        .get("/notifications")
        .then((response) => {
          commit("SET_NOTIFICATIONS_DATA", response.data);
        })
        .catch((err) => {
          console.error("Error from getFilteredTrackingProject: ", err);
        });
    },
  },
};
