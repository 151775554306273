<template>
  <div class="heading">
    <div class="logo">
      <img src="@/modules/assets/Logo.svg" alt="logo" />
    </div>

    <h2 class="green">{{ title }}</h2>

    <div class="heading-right">
      <NotificationsBar />
      <UserProfile />
    </div>
  </div>
</template>

<script>
import UserProfile from "./UserProfile.vue";
import NotificationsBar from "../../modules/components/base/NotificationsBar.vue";

export default {
  components: {NotificationsBar, UserProfile },
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 57px 7px 52px;
  background-color: var(--col-bg-secondary);

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    border-radius: 50%;
    background-color: var(--col-info);
  }
  &-right {
    display: flex;
  }
}
.logo {
  height: 55px;
}
</style>
