<template>
  <div class="menu" :class="{ menu_full: !isMenuShown }">
    <div class="menu-heading">
      <div
        class="menu-icon-wrapper pointer"
        @click="isMenuShown = !isMenuShown"
      >
        <IconWallet icon-color="#17a2b8" />
      </div>
      <p v-if="isMenuShown" class="small-text">{{ todayDate }}</p>
    </div>

    <ul>
      <li v-for="item in menuListAdmin" :key="item">
        <router-link :to="{ name: `${item.link}` }">
          <div class="menu-icon-wrapper">
            <component
              :is="item.icon"
              :active="item.listen ?? false"
              icon-color="#828282"
              width="20"
              height="24"
            />
          </div>

          <div v-if="isMenuShown" class="menu-text">
            {{ item.name }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import AdminSign from "../modules/components/AdminSign.vue";
import convertDateFormat from "@/modules/helpers/convertDateFormat.js";
import IconWallet from "@/modules/components/icons/IconWallet";
import IconInfo from "@/modules/components/icons/IconInfo";
import IconFlame from "@/modules/components/icons/IconFlame";
import IconFolder from "@/modules/components/icons/IconFolder";
import IconGem from "@/modules/components/icons/IconGem";
import IconMessenger from "@/modules/components/icons/IconMessenger";
import { useStore } from "vuex";

export default {
  components: {
    AdminSign,
    IconWallet,
    IconInfo,
    IconFlame,
    IconFolder,
    IconGem,
    IconMessenger,
  },
  setup() {
    const store = useStore();

    const notificationsProjectFeasibilityMessages = computed(() => store.getters["events/notificationsProjectFeasibilityMessages"]);
    const notificationsProjectProjectMessages = computed(() => store.getters["events/notificationsProjectProjectMessages"]);
    // const notificationsAdminPSMessages = computed(() => store.getters["events/notificationsAdminPSMessages"]);
    store.dispatch("events/getUsersNotifications");

    const menuListAdmin = ref([
      {
        name: "Active Feasibilities",
        link: "FeasibilityList",
        icon: "IconGem",
        listen: notificationsProjectFeasibilityMessages,
      },
      {
        name: "Active Projects",
        link: "ActiveList",
        icon: "IconFlame",
        listen: notificationsProjectProjectMessages,
      },
      {
        name: "Archived Projects",
        link: "ArchivedProjects",
        icon: "IconFolder",
      },
      {
        name: "Orders Tracking",
        link: "OrdersTracking",
        icon: "IconInfo",
      },
      {
        name: "Messenger",
        link: "Messenger",
        icon: "IconMessenger",
      },
    ]);
    const isMenuShown = ref(true);
    const todayDate = convertDateFormat.formatCurrentDate();

    return { menuListAdmin, isMenuShown, todayDate };
  },
};
</script>

<style lang="scss" scoped>
.small-text {
  width: 246px;
  font-size: 12px;
  font-family: "SFProDisplay-Regular";
  text-align: center;
  color: var(--col-secondary);
}

.menu {
  width: 246px;
  // margin-top: 5px;
  background: #ffffff;

  &_full {
    width: auto;
  }

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 18px;
    border-bottom: 1px solid var(--col-primary-light);
  }

  &-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &-text {
    color: grey;
    font-family: "OpenSans-Semibold";
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--col-primary);
  }
}
li {
  border-bottom: 1px solid var(--col-primary-light);
  transition: 0.17s all;
}
li > a {
  display: flex;
  justify-content: space-between;
  padding: 16px 5px 12px 18px;

  &:hover,
  &.router-link-active,
  &:focus {
    background-color: var(--col-bg-primary);
  }
}
</style>
