<template>
  <svg
    v-bind="$attrs"
    :width="width"
    :height="height"
    :fill="$attrs.active ? '#17a2b8':'#828282'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 520 520"
  >
     <path d="m512 166c0-3.103-1.032-6.403-3.048-9.064l-91-120c-2.814-3.705-7.311-5.936-11.952-5.936h-300c-4.586 0-9.113 2.192-11.952 5.936l-91 120c-2.014 2.659-3.048 5.866-3.048 9.064 0 3.445 1.243 6.826 3.306 9.394l241 300c2.905 3.605 7.269 5.606 11.694 5.606 4.474 0 8.828-2.05 11.694-5.606l241.001-300c2.067-2.572 3.305-5.973 3.305-9.394zm-408.627-91.712 38.356 76.712h-96.529zm222.467 106.712-69.84 232.799-69.84-232.799zm-129.84-30 60-80 60 80zm90-90h95.73l-38.292 76.584zm-117.438 76.584-38.291-76.584h95.729zm-13.722 43.416 64.698 215.661-173.247-215.661zm202.321 0h108.549l-173.247 215.66zm13.11-30 38.356-76.712 58.173 76.712z" />
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 24 },
    height: { type: [Number, String], default: 24 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
