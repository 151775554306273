<template>
  <div class="person-wrapper">
    <button class="person" @click="isOpenMenu = !isOpenMenu">
      <div v-if="user" class="person-photo">
        <img :src="user.avatar" :alt="user.name" />
      </div>
      <IconTriangle class="arrow" />
    </button>

    <div v-show="isOpenMenu" class="person-menu">
      <div class="person-menu-heading">
        <div v-if="user" class="person-menu-heading-photo">
          <img :src="user.avatar" :alt="user.name" />
        </div>

        <div v-if="user" class="person-menu-heading-description">
          <p v-if="user.name" class="person-menu-heading-text">
            {{ user.name }}
          </p>
          <p v-if="user.email" class="person-menu-heading-text">
            {{ user.email }}
          </p>
          <p v-if="userInfo" class="person-menu-title">
            {{ userInfo.procurement_site.title }}
          </p>
        </div>
      </div>

      <hr />

      <ul>
        <li v-for="(item, index) in profileNav" :key="index">
          <a :href="item.path">
            <component :is="item.icon" />
            <div class="person-menu-text">
              {{ item.name }}
            </div>
          </a>

          <hr />
        </li>
        <li @click.prevent="signOut">
          <a href="">
            <IconLogout />
            <div class="person-menu-text">Logout</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import IconBell from "@/modules/components/icons/IconBell.vue";
import IconGear from "@/modules/components/icons/IconGear.vue";
import IconMan from "@/modules/components/icons/IconMan.vue";
import IconLogout from "@/modules/components/icons/IconLogout.vue";
import IconTriangle from "@/modules/components/icons/IconTriangle.vue";

export default {
  components: {
    // IconBell,
    IconGear,
    IconMan,
    IconLogout,
    IconTriangle,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const authenticated = computed(() => store.getters["auth/authenticated"]);
    const user = computed(() => store.getters["auth/user"]);
    if (user.value) {
      store.dispatch("auth/getUserInfo", user.value.id);
    }

    const userInfo = computed(() => store.getters["auth/userInfo"]);
    const isOpenMenu = ref(false);
    // const numEvents = ref(3);
    const profileNav = ref([
      // { name: `${numEvents.value} Events`, path: "/", icon: "IconBell" },
      { name: "My Account", path: "/user-account", icon: "IconMan" },
    ]);

    const signOut = async () => {
      let result = await store.dispatch("auth/signOut");
      if (result) {
        router.replace({ name: "Login" });
      }
    };

    return { authenticated, user, userInfo, isOpenMenu, profileNav, signOut };
  },

  created: function () {
    let self = this;

    window.addEventListener("click", function (e) {
      if (!self.$el.contains(e.target)) {
        self.isOpenMenu = false;
      }
    });
  },
};
</script>
