<template>
  <Loader v-if="isLoading" />

  <Heading v-if="isAuthOnly" />

  <Notification />

  <div :class="{ 'main-wrapper': isAuthOnly }">
    <Menu v-if="isAuthOnly" />
    <div :class="{ main: isAuthOnly }">
      <router-view />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Loader from "./modules/components/base/Loader.vue";
import Heading from "@/components/base/Heading.vue";
import Notification from "@/modules/components/base/Notification.vue";
import Menu from "./components/Menu.vue";

export default {
  components: { Loader, Heading, Menu, Notification },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isAuthOnly = computed(() => route.meta.authOnly);
    const isLoading = computed(() => store.getters["projects/loading"]);

    onMounted(async () => {
      const user = computed(() => store.getters["auth/user"]);
      const subscribedOnMe = computed(() => store.getters["events/subscribedOnMe"]);
      if (user.value != null) {
        if (subscribedOnMe.value) {
          await store.dispatch("events/subscribeUsersChannel", user.value);
        }
      }
    });

    return { isAuthOnly, isLoading };
  },
};
</script>

<style lang="scss">
@import "./index.scss";

.main-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: calc(100vh - 90px);
}

.main {
  position: relative;
  overflow-x: auto;
  flex: 1;
  padding: 72px 30px 25px;
}
</style>
