import Pusher from "pusher-js";

const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  authEndpoint: process.env.VUE_APP_PUSHER_APP_AUTH_ENDPOINT,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authTokenUser")}`,
    },
  },
});

export default pusher;
