import axios from "@/api/axios";

export default {
  namespaced: true,

  state: {
    loading: false,
    projects: [],
    pagination: {},
    project: null,
    projectCategories: [],
    trackingProject: {},
  },

  getters: {
    loading: (state) => state.loading,
    projects: (state) => state.projects,
    pagination: (state) => state.pagination,
    project: (state) => state.project,
    lineProposal: (state) => state.lineProposal,
    projectCategories: (state) => state.projectCategories,
    trackingProject: (state) => state.trackingProject,
  },

  mutations: {
    LOADING_STATE(state, payload) {
      state.loading = payload;

      setTimeout(() => {
        state.loading = false;
      }, 10000);
    },
    GET_PROJECTS(state, payload) {
      state.projects = payload;

      setTimeout(() => {
        state.loading = false;
      }, 10000);
    },
    GET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    GET_PROJECT(state, payload) {
      state.project = payload;
    },
    SET_CATEGORIES(state, data) {
      state.projectCategories = data;
    },
    GET_TRACKING_PROJECT(state, data) {
      state.trackingProject = data;
    },
  },

  actions: {
    async getFeasibilityProjects({ commit, dispatch }, page) {
      const newPage = page ? `?page=${page}` : "";
      commit("GET_PROJECTS", []);
      commit("LOADING_STATE", true);
      await axios
        .get("/feasibility-projects" + newPage)
        .then((response) => {
          commit("GET_PROJECTS", response.data.data.data);
          dispatch("getPagination", response.data.data);
        })
        .catch((err) => {
          console.error("Error from getFeasibilityProjects: ", err);
        });
      commit("LOADING_STATE", false);
    },
    async getActiveProjects({ commit, dispatch }, page) {
      const newPage = page ? `?page=${page}` : "";
      commit("GET_PROJECTS", []);
      commit("LOADING_STATE", true);
      await axios
        .get("/active-projects" + newPage)
        .then((response) => {
          commit("GET_PROJECTS", response.data.data.data);
          dispatch("getPagination", response.data.data);
        })
        .catch((err) => {
          console.error("Error from getActiveProjects: ", err);
        });
      commit("LOADING_STATE", false);
    },
    async getArchivedProjects({ commit, dispatch }, page) {
      const newPage = page ? `?page=${page}` : "";
      commit("GET_PROJECTS", []);
      commit("LOADING_STATE", true);
      await axios
        .get("/archived-projects" + newPage)
        .then((response) => {
          commit("GET_PROJECTS", response.data.data.data);
          dispatch("getPagination", response.data.data);
        })
        .catch((err) => {
          console.error("Error from getArchivedProjects: ", err);
        });
      commit("LOADING_STATE", false);
    },
    async getFilteredProjects({ commit, dispatch }, { params, page }) {
      commit("LOADING_STATE", true);
      console.log(88, params);

      const newPage = page ? `&page=${page}` : "";
      let query = [];

      for (const p in params) {
        query.push(`${p}=${params[p]}`);
      }

      const q = query.join().replace(/,/g, "&");

      await axios
        .get(`/search-projects?${q}${newPage}`)
        .then((response) => {
          if (response.data.status === "Success") {
            commit("GET_PROJECTS", response.data.data.data);
            dispatch("getPagination", response.data.data);
          }
        })
        .catch((err) => {
          console.error("Error from getAllProjects: ", err);
        });
      commit("LOADING_STATE", false);
    },
    getPagination({ commit }, data) {
      let pagination = {};
      for (const key in data) {
        if (key !== "data" && key !== "links") {
          pagination[key] = data[key];
        }
      }

      commit("GET_PAGINATION", pagination);
    },
    async getCategories({ commit }) {
      commit("SET_CATEGORIES", []);
      commit("LOADING_STATE", true);
      await axios
        .get("/category")
        .then((response) => {
          if (response.data.status === "Success") {
            commit("SET_CATEGORIES", response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error getCategories: ", error);
        });
      commit("LOADING_STATE", false);
    },
    async getProject({ commit, dispatch }, id) {
      commit("GET_PROJECT", null);
      commit("LOADING_STATE", true);
      await axios
        .get("/project/" + id)
        .then((response) => {
          commit("GET_PROJECT", response.data.data);
        })
        .catch((err) => {
          console.error("Error from getProject: ", err);
        });
      commit("LOADING_STATE", false);
      dispatch("chat/getChat", { id }, { root: true });
    },
    async setProjectLineProposal({ commit, dispatch }, { order, projectId }) {
      commit("LOADING_STATE", true);
      await axios
        .post("/project-line-proposal", order)
        .then(() => {
          dispatch("getProject", projectId);
        })
        .catch((err) => {
          console.error("Error from setProjectLineProposal: ", err);
        });
      commit("LOADING_STATE", false);
    },
    async editProjectLineProposal({ commit }, {order, proposalId}) {
      commit("LOADING_STATE", true);
      await axios
        .put(`/project-line-proposal/${proposalId}`, order)
        .catch((err) => {
          console.error("Error from editProjectLineProposal: ", err);
        });
      commit("LOADING_STATE", false);
    },
    async setTrackingShipment({ commit }, shipment) {
      commit("LOADING_STATE", true);
      await axios.post("/proposal-shipment", shipment).catch((err) => {
        console.error("Error from setTrackingShipment: ", err);
      });
      commit("LOADING_STATE", false);
    },
    async getTrackingProject({ commit }, id) {
      commit("LOADING_STATE", true);
      commit("GET_TRACKING_PROJECT", null);
      if (id) {
        await axios.get(`project-tracking?projectId=${id}`).then((res) => {
          commit("GET_TRACKING_PROJECT", res.data.data);
        });
      } else {
        await axios.get("project-tracking").then((res) => {
          commit("GET_TRACKING_PROJECT", res.data.data);
        });
      }
      commit("LOADING_STATE", false);
    },
  },
};
