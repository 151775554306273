<template>
  <svg
    v-bind="$attrs"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    :fill="$attrs.active ? '#17a2b8':'#828282'"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4490:64962)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 18.7505C12.3206 18.7505 14.5462 17.8286 16.1872 16.1877C17.8281 14.5467 18.75 12.3211 18.75 10.0005C18.75 7.67984 17.8281 5.45425 16.1872 3.8133C14.5462 2.17236 12.3206 1.25049 10 1.25049C7.67936 1.25049 5.45376 2.17236 3.81282 3.8133C2.17187 5.45425 1.25 7.67984 1.25 10.0005C1.25 12.3211 2.17187 14.5467 3.81282 16.1877C5.45376 17.8286 7.67936 18.7505 10 18.7505V18.7505ZM10 20.0005C12.6522 20.0005 15.1957 18.9469 17.0711 17.0716C18.9464 15.1962 20 12.6527 20 10.0005C20 7.34832 18.9464 4.80478 17.0711 2.92942C15.1957 1.05406 12.6522 0.000488281 10 0.000488281C7.34784 0.000488281 4.8043 1.05406 2.92893 2.92942C1.05357 4.80478 0 7.34832 0 10.0005C0 12.6527 1.05357 15.1962 2.92893 17.0716C4.8043 18.9469 7.34784 20.0005 10 20.0005V20.0005Z"
        :fill="$attrs.active ? '#17a2b8':'#828282'"/>
      <path
        d="M11.1624 8.23535L8.29994 8.5941L8.19744 9.0691L8.75994 9.17285C9.12744 9.26035 9.19994 9.39285 9.11994 9.7591L8.19744 14.0941C7.95494 15.2154 8.32869 15.7429 9.20744 15.7429C9.88869 15.7429 10.6799 15.4279 11.0387 14.9954L11.1487 14.4754C10.8987 14.6954 10.5337 14.7829 10.2912 14.7829C9.94744 14.7829 9.82244 14.5416 9.91119 14.1166L11.1624 8.23535Z"
        :fill="$attrs.active ? '#17a2b8':'black'"/>
      <path
        d="M10 6.87549C10.6904 6.87549 11.25 6.31584 11.25 5.62549C11.25 4.93513 10.6904 4.37549 10 4.37549C9.30964 4.37549 8.75 4.93513 8.75 5.62549C8.75 6.31584 9.30964 6.87549 10 6.87549Z"
        :fill="$attrs.active ? '#17a2b8':'black'"/>
    </g>
    <defs>
      <clipPath id="clip0_4490:64962">
        <rect
          :width="width"
          :height="height"
          fill="white"
          transform="translate(0 0.000488281)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: 20 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
