<template>
  <svg
    v-bind="$attrs"
    :width="width"
    :height="height"
    viewBox="0 0 13 19"
    :fill="$attrs.active ? '#17a2b8':'#828282'"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.61036 18.9404C3.08701 18.9404 0.220703 16.0582 0.220703 12.5153C0.220703 10.9714 0.746613 9.92165 1.35574 8.7062C1.69378 8.03123 2.04346 7.33342 2.36637 6.44299L2.70232 5.51655C3.19769 5.63787 3.02985 5.59683 3.52508 5.71815L3.3948 6.69502C3.28819 7.49327 3.43388 8.39869 3.79434 9.17901C3.94718 9.51005 4.13169 9.80383 4.3365 10.048C4.26548 8.75187 4.39828 7.50251 4.73367 6.32041C5.0853 5.08128 5.64946 3.93882 6.41058 2.92455C7.35789 1.66245 8.44586 0.838138 8.95033 0.770333L10.3801 0.578125L9.41797 1.6532C8.98088 2.14143 8.74019 2.77171 8.74019 3.4279C8.74019 4.69631 9.51827 5.59641 10.4191 6.6387C10.9967 7.3068 11.6512 8.06415 12.147 8.99451C12.7209 10.0715 12.9999 11.2231 12.9999 12.5153C12.9999 16.0582 10.1336 18.9404 6.61036 18.9404V18.9404ZM2.51221 8.77302C2.44272 8.91381 2.37436 9.05027 2.30781 9.18321C1.73665 10.323 1.28569 11.2233 1.28569 12.5153C1.28569 15.4709 3.67428 17.8754 6.61036 17.8754C9.54629 17.8754 11.935 15.4709 11.935 12.5153C11.935 10.021 10.7547 8.65562 9.6134 7.3351C8.61663 6.182 7.67521 5.09277 7.67521 3.4279C7.67521 3.29509 7.68221 3.16313 7.69622 3.03228C7.39586 3.37215 7.08051 3.78416 6.78239 4.27042C5.94085 5.64334 5.03809 7.94717 5.51735 11.127L5.65744 12.0575L4.7876 11.6986C3.98879 11.3688 3.29267 10.6326 2.82742 9.62549C2.69993 9.34936 2.59458 9.06371 2.51221 8.77302V8.77302Z"
      :fill="$attrs.active ? '#17a2b8':'#828282'"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: { type: [Number, String], default: 17 },
    height: { type: [Number, String], default: 25 },
    iconColor: { type: String, default: "#828282" },
  },
};
</script>
