import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import chat from "./modules/chat";
import globalMessenger from "./modules/globalMessenger";
import projects from "./modules/projects";
import notifications from "./modules/notifications";
import events from "./modules/events";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    chat,
    globalMessenger,
    projects,
    notifications,
    events,
  },
  plugins: [createPersistedState()],
});
